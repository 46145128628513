// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";

import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    デジタルトランスフォーメーション
                    <br />
                    戦略
                  </>
                }
                description="デジタル変革戦略は、ビジネスの未来を形作る鍵です。"
              />
              <RotatingCardBack
                image={bgBack}
                title="もっと探索する"
                description="私たちがどのようにお手伝いできるかについて知りたい場合は、お問い合わせください。"
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/contact-us",
                  label: "お問い合せ",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="ビジョンの策定（ビジョンの確立）"
                  description="デジタルトランスフォーメーションの最初のステップは、組織全体のビジョンを策定することです。どのような目標を達成し、どのような価値を提供したいのかを明確に定義します。このビジョンは組織全体に共有され、方向性を示すものとなります。"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="リーダーシップの確立"
                  description="デジタルトランスフォーメーションを成功させるためには、組織内でリーダーシップを確立することが不可欠です。デジタルトランスフォーメーションの責任者や専門家を指名し、変革を推進する役割を明確にします。"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="デジタルストラテジーの策定"
                  description="次に、デジタルストラテジーを策定します。どのデジタル技術を導入し、どのプロセスを改善するかを計画し、目標に合わせて具体的な戦略を立てます。"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="テクノロジーの選定と導入"
                  description="適切なテクノロジープラットフォームやツールを選定し、組織に導入します。これにはクラウドコンピューティング、ビッグデータ解析、人工知能、IoTなどが含まれます。"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="プロセスの再設計"
                  description="デジタルトランスフォーメーションの一環として、既存の業務プロセスを見直し、最適化します。デジタルテクノロジーを活用して効率を向上させ、生産性を高めます。"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="データの活用"
                  description="データはデジタルトランスフォーメーションにおいて非常に重要です。データの収集、分析、活用を強化し、意思決定のサポートに役立てます。"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="スキルとトレーニング"
                  description="組織のメンバーに必要なデジタルスキルを提供し、トレーニングを行います。デジタル技術の変化に追随できるようにするために、教育と訓練が欠かせません。"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="変革の文化の浸透"
                  description="デジタルトランスフォーメーションの成功には、変革を受け入れる文化の浸透が必要です。組織全体で変革を支持し、新しいアイデアやアプローチを奨励します。"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
