/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/engg-into.jpg";

function ITEnggIntro() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ITエンジニア紹介サービス</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              日本の多くの企業や組織は、デジタルトランスフォーメーション（DX）を進めています。これは、伝統的なビジネスプロセスをデジタル技術に基づく効率的なプロセスに変革することを指します。ITエンジニアは、DXプロジェクトの設計、実装、および管理に必要です。DXの成功は、企業の競争力と効率性に直接影響を与えます。
              日本の企業は、新たなビジネスモデルを模索し、イノベーションを推進しようとしています。これは、新たな市場機会を探求し、競争に勝ち抜くための必要条件です。ITエンジニアは、新しいアプリケーション、プロダクト、サービスの開発に欠かせず、企業の成長と競争力を支えます。
              <br /> <br /> <br />{" "}
              私たちは、最高品質のエンジニアを輩出するインドのトップ大学と素晴らしい関係を築いています。
              私たちは、プロジェクトのためにこれらの大学 (私たちが卒業した大学を含む)
              の優秀なエンジニアリング人材にインタビューし、スキャンするプロセスを持っています。
              また、インドの語学学校とも提携しており、クライアントのプロジェクトに配属される前にエンジニアに日本語を教えることができます。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ITEnggIntro;
