/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/bigdata-one-img.jpg";

function BigDataCapture() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">従来の組織におけるビッグデータのキャプチャ</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              レガシー組織におけるビッグデータの収集（データキャプチャ）は、現代のビジネス環境において重要な課題です。ビッグデータは、大量のデータから得られる洞察を活用し、戦略的な意思決定や業績向上に貢献します。しかし、レガシーシステムを使用している組織では、データの収集と活用における課題が存在します。本稿では、レガシー組織におけるビッグデータ収集に関する課題と、その解決策について考察します。
              <br /> <br /> <br />
              まず、レガシーシステムは、古い技術やデータ構造を使用しているため、ビッグデータの収集に制約が生じることがあります。例えば、レガシーシステムはデータの集約や統合が困難であり、異なるデータソースからの情報の統合が難しい場合があります。また、データのフォーマットやスキーマが統一されていないため、データの一貫性や品質の確保が難しくなることもあります。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BigDataCapture;
