/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/bigdata-two.jpg";

function BigDataVisualization() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ビッグデータの可視化</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              ビッグデータの可視化は、情報爆発時代において複雑なデータをわかりやすく視覚化する重要な手法です。グラフ、チャート、ダッシュボードを用い、ビジュアルな要素を通じてデータの傾向やパターンを素早く理解可能にします。これにより、企業はリアルタイムで意思決定し、戦略を策定できるようになります。
              <br /> <br /> <br />
              可視化はまた、異常値や潜在的な機会の発見に寄与し、ビジネスプロセスを最適化します。ビッグデータの可視化は、データ駆動の未来において不可欠なツールとなっています。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BigDataVisualization;
