/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/digital-consulting.jpg";

function ITConsulting() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ITコンサルティング</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              ITコンサルティングサービスは、現代のビジネス環境においてますます重要な役割を果たしています。テクノロジーの急速な進化に伴い、企業は競争力を維持し、成長を遂げるためにITの効果的な活用が不可欠となっています。この記事では、ITコンサルティングサービスの重要性、その提供方法、およびビジネスにもたらす利益について詳しく探究します。
              <br /> <br /> <br /> 戦略的計画:
              ITコンサルタントは、企業の長期的なビジョンに合わせてテクノロジー戦略を策定します。これには、新しいシステムの導入、既存のシステムのアップグレード、セキュリティ強化などが含まれます。
              プロジェクト管理:
              大規模なITプロジェクトを成功させるために、プロジェクトマネジメントの専門知識が必要です。ITコンサルタントは、プロジェクトの計画、実行、監視、制御を効果的に行います。
              システムインテグレーション:
              異なるシステムやアプリケーションを統合し、スムーズなデータフローを確保することは、多くの企業にとって重要です。ITコンサルタントは、システムの統合を効率的に実現します。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ITConsulting;
