/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/bigdata-three.jpg";

function BigDataPipeline() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ビッグデータパイプライン構築</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              ビッグデータパイプラインの構築は、大量かつ多様なデータを収集、処理、分析するための骨格です。このプロセスは、データの収集から蓄積、変換、最終的な結果の提供まで、シームレスなフローを確立します。各ステップでのデータ品質の確保やリアルタイム処理の最適化が鍵となります。
              <br /> <br /> <br />
              ビッグデータパイプラインの構築により、企業は迅速な意思決定と洞察獲得を可能にし、競争力を高めることができます。技術的な専門知識と継続的な最適化が、効果的なビッグデータ活用に欠かせません。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BigDataPipeline;
