/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/digital-staff.jpg";

function ITConsultant() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">コンサルタント</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              デジタルコンサルタントは、現代のビジネス環境において重要な役割を果たしています。デジタルコンサルタントは、企業や組織に対してデジタル技術の活用やデジタル化の戦略を提案し、ビジネスの成長や競争力の向上をサポートします。本稿では、デジタルコンサルタントの役割と貢献について考察します。
              <br /> <br /> <br />
              デジタルコンサルタントの役割の一つは、デジタル化の戦略の策定と実行支援です。デジタル技術の進歩により、ビジネスのあり方や顧客との関係性が変化しています。デジタルコンサルタントは、企業のビジョンや目標に基づき、デジタル化の方向性や目標を定め、実現するためのロードマップを策定します。さらに、デジタル化の戦略を実行するために、必要なプロジェクトやイニシアチブの計画と推進をサポートします。
              <br /> <br /> <br />
              デジタルコンサルタントは、ビジネスプロセスの改善や効率化を支援します。デジタル化により、従来のプロセスや手続きを効率化し、生産性や柔軟性を向上させることができます。デジタルコンサルタントは、企業の業務プロセスを評価し、デジタルテクノロジーや自動化の導入による改善の機会を特定します。そして、適切なツールやシステムの選定、導入、展開をサポートし、効果的な変革を実現します。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ITConsultant;
