// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

import MKTypography from "components/MKTypography";

import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Shristi 合同会社",
    image: logoCT,
    route: "/",
  },
  socials: [
    // {
    //   icon: <FacebookIcon />,
    //   link: "https://www.facebook.com//",
    // },
    // {
    //   icon: <TwitterIcon />,
    //   link: "https://twitter.com/",
    // },
    // {
    //   icon: <GitHubIcon />,
    //   link: "https://github.com/",
    // },
    // {
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/channel/-rOvB9oHkzZD1w",
    // },
  ],
  menus: [
    {
      name: "リンク",
      items: [{ name: "会社概要", route: "/pages/landing-pages/company" }],
    },
    {
      name: "コネクト",
      items: [
        { name: "電話番号: (+81) 03-5050-4529", href: "tel: 03-5050-4529" },
        { name: "メール: sales@shristi.jp", href: "mailto: sales@shristi.jp" },
        {
          name: "住所: 東京都 港区赤坂4丁目8番19号　赤坂フロントタウン3階",
          href: "https://maps.app.goo.gl/JFVGWTj7To4RJtH19",
        },
      ],
    },
    {
      name: "ヘルプサポート",
      items: [{ name: "お問い合わせ", route: "/pages/landing-pages/author" }],
    },
    {
      name: "法律上",
      items: [{ name: "プライバシーポリシー", route: "/pages/landing-pages/privacy" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Shristi{" "}
      {/* <MKTypography
        component="a"
        href="https://www.vvvvv.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        zzz
      </MKTypography> */}
      .
    </MKTypography>
  ),
};
