// const imagesPrefix =
//   "https://raw.githubusercontent.com/qqqqq/public-assets/master/material-design-system/presentation/sections";

import recruitment from "assets/images/recruitment.jpeg";
import consulting from "assets/images/consulting.jpg";
import appmgmt from "assets/images/appmgmt.jpeg";

export default [
  {
    title: "ITサービス",
    description: "重点分野は 3 つです",
    items: [
      {
        image: `${recruitment}`,
        name: "ITエンジニア紹介サービス",
        // count: 10,
        route: "pages/landing-pages/services",
      },
      {
        image: `${consulting}`,
        name: "ITコンサルティング",
        // count: 14,
        route: "pages/landing-pages/services",
      },
      {
        image: `${appmgmt}`,
        name: "ITアプリケーション管理",
        route: "pages/landing-pages/services",
        // count: 8,
        // pro: true,
      },
    ],
  },
];
