// @mui material components
import Grid from "@mui/material/Grid";
import { useState } from "react";

import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import axios from "axios";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

// function handleSubmit(name, email, comment) {
//   alert(" email: " + email);
//   alert(" comment: " + comment);
//   alert(" name: " + name);
// }

function ContactUs() {
  const [email, setEmail] = useState();
  const [comment, setComment] = useState();
  const [name, setName] = useState();
  const TOKEN = "3nw7GdvrQB-LMygWr44aRw";

  function resetForm() {
    setEmail("");
    setComment("");
    setName("");
  }

  function handleSubmit(name, email, comment) {
    const url = "https://client-shr-server-side-7hj2f2yeba-an.a.run.app";
    const postData = {
      name: name,
      email: email,
      phone: "000000",
      subject: "Contact from Shristi.jp NEW SITE",
      message: comment,
    };

    axios
      .post(url + "/swserve/contactreq", postData, {
        headers: {
          "X-Auth-Token": TOKEN,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        alert("メッセージが送信されました");
        resetForm();
        console.log(data.error_message);
      })
      .catch((err) => {
        console.log(err);
        resetForm();
        // this.showSuccessAlert = false;
      });

    resetForm();
  }

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/contact",
            label: "お問い合わせ",
            color: "info",
          }}
        />
      </MKBox>
      {/* <form onSubmit={handleSubmit}> */}
      {/* <form
        onSubmit={(e) => {
          alert(" submitted: " + e);
        }}
      > */}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                お問い合わせ
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                パートナーシップの機会など、さらにご質問がございましたら、sales@shristi.jp
                まで電子メールでお問い合わせいただくか、お問い合わせフォームを使用してご連絡ください。
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="名前"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="メール"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="質問またはコメント?"
                      placeholder="質問またはコメント"
                      onChange={(e) => setComment(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    // type="submit"
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      handleSubmit(name, email, comment);
                    }}
                  >
                    送信
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      {/* </form> */}

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
