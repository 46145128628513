import Icon from "@mui/material/Icon";

// Pages
import AboutUs from "layouts/pages/landing-pages/company";
import Services from "layouts/pages/landing-pages/services";
import Consulting from "layouts/pages/landing-pages/consulting";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Home from "layouts/pages/presentation";
import Privacy from "layouts/pages/landing-pages/privacy";
import Staffing from "layouts/pages/landing-pages/staffing";
import BigData from "layouts/pages/landing-pages/bigdata";

const routes = [
  {
    name: "ホーム",
    route: "/",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    component: <Home />,
    rowsPerColumn: 2,
  },
  {
    name: "会社概要",
    route: "/pages/landing-pages/company",
    component: <AboutUs />,
    collapse: [
      {
        name: "会社概要",
        description: "会社概要について",
        route: "/pages/landing-pages/company",
        component: <AboutUs />,
      },
      {
        name: "プライバシー",
        description: "プライバシーについて",
        route: "/pages/landing-pages/Privacy",
        component: <Privacy />,
      },
      {
        name: "お問い合わせ",
        description: "お問い合わせ",
        route: "/pages/landing-pages/contact-us",
        component: <ContactUs />,
      },
    ],
  },
  {
    name: "サービス",
    route: "/pages/landing-pages/services",
    component: <Services />,
    collapse: [
      {
        name: "コンサルティング",
        description: "ITコンサルティングサービス",
        route: "/pages/landing-pages/consulting",
        component: <Consulting />,
      },
      {
        name: "人材派遣サービス",
        description: "人材派遣サービス",
        route: "/pages/landing-pages/staffing",
        component: <Staffing />,
      },
      {
        name: "ビッグデータコンサルティング",
        description: "ビッグデータコンサルティング",
        route: "/pages/landing-pages/bigdata",
        component: <BigData />,
      },
    ],
  },
];

export default routes;
