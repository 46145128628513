/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/digital-business-model.jpg";

function ITDigitalTransformation() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">デジタルビジネスモデル変革</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              デジタルビジネスモデル変革（デジタルビジネスモデルへんかく）は、現代のビジネス環境において重要な要素となっています。デジタル技術の進歩により、従来のビジネスモデルが変革し、新たなビジネスの形態が生まれています。デジタルビジネスモデル変革を実施することで、企業は競争力を強化し、市場の変化に適応することができます。
              <br />
              <br />
              <br />
              デジタルビジネスモデル変革は、デジタル技術を活用した新たな価値の提供方法を探求することから始まります。従来のビジネスモデルでは、製品の販売やサービスの提供が主軸でしたが、デジタルビジネスモデルでは、デジタル技術を活用した新たな付加価値を提供することが求められます。例えば、デジタルプラットフォームの提供やデータ駆動型のサービスなどが挙げられます。
              <br />
              <br />
              <br />
              デジタルビジネスモデル変革は、顧客体験の向上やパーソナライズされたサービスの提供により、顧客との関係を強化します。デジタル技術を活用することで、顧客はより便利で個別化された体験を享受することができます。例えば、AI（人工知能）や機械学習を活用した顧客の嗜好や行動の予測に基づくカスタマイズされたサービスの提供が可能となります。また、デジタルビジネスモデルでは、オムニチャネルの展開やモバイルコマースの活用により、顧客が自由にチャネルを選択できる環境を提供することも重要です。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ITDigitalTransformation;
