// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/bg2.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">プライバシーポリシー</MKTypography>
                {/* <MKButton variant="outlined" color="info" size="small">
                  Follow
                </MKButton> */}
              </MKBox>
              <Grid container spacing={3} mb={3}>
                <Grid item>
                  {/* <MKTypography component="span" variant="body2" fontWeight="bold">
                    323&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Posts
                  </MKTypography> */}
                </Grid>
                <Grid item>
                  {/* <MKTypography component="span" variant="body2" fontWeight="bold">
                    3.5k&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Followers */}
                  {/* </MKTypography> */}
                </Grid>
                <Grid item>
                  {/* <MKTypography component="span" variant="body2" fontWeight="bold">
                    260&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Following
                  </MKTypography> */}
                </Grid>
              </Grid>
              <MKTypography variant="body1" fontWeight="normal" color="text">
                （個人情報保護に関する基本方針）
                <br />
                当社は、個人情報の適正なる管理による保護が個人のプライバシーを護る観点から、また同時に当社と当社株主の利益を護り企業価値を高めることになるとの認識の下に、個人情報の保護に関する法律その他関係法令を遵守するとともに、以下のプライバシーポリシー（個人情報保護方針）を定めております。
                <br />
                1．個人情報の取り扱い
                <br />
                当社が取り扱う個人情報には以下のものがあります。
                <br /> 【取引先担当者情報】
                <br />{" "}
                当社とお取引のある企業の役職員に関する個人情報であり、情報の大部分は名刺に記載されている情報。但し、一部例外として、商談等を通して個人の住所、家族構成、趣味等の追加的情報を入手し、保有することがあります。
                <br /> 【個人株主情報】
                <br /> 証券代行会社から提出される株主名簿に記載された個人株主の情報
                <br /> 【採用応募者情報】
                <br />{" "}
                当社の採用に応募した人の個人情報であり、提出された履歴書等、採用に際して入手した情報
                <br /> 【当社役職員の情報】
                <br />{" "}
                当社役員・従業員、派遣・出向・契約社員、パートタイマーに関する個人情報であり、採用時に提出された履歴書に記載の事項の他、業務を通して集積される管理上必要な諸情報
                <br /> 2．利用目的の特定
                <br />{" "}
                当社は、あらかじめご本人の同意を得た上で個人情報を次の目的のために利用し、他の目的のために利用することはありません。但し、他の目的のために利用する必要が生じた場合は、その目的を出来る限り特定した上で通知又は公表します。
                <br /> 【取引先担当者情報】
                <br />{" "}
                日々の営業活動に必要な連絡等のために利用する他、製品のご紹介や催し物案内等のため、更に季節のご挨拶等のために利用することがあります。
                <br /> 【個人株主情報】
                <br />{" "}
                法定の開示のための参照や法定書類を送付するために利用する他、株主優待を実施することとなった場合は、そのための連絡又は物品等の送付のために利用します。
                <br /> 【採用応募者情報】
                <br /> 採用の可否を判断するために利用します。
                <br /> 【当社役職員情報】
                <br /> 人事管理上の諸目的のために利用します。
                <br /> 3．第三者への開示及び提供
                <br />{" "}
                当社は、以下の場合を除いてご本人の同意なく第三者に開示又は提供することはありません。
                <br /> ご本人の同意がある場合
                <br /> 法律により開示が義務付けられている場合及び公的機関から開示を求められた場合
                <br />{" "}
                人の生命、身体又は財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合
                <br />{" "}
                国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                <br /> 但し、次に掲げる場合は上記に定める第三者には該当しません。
                <br />{" "}
                お問い合わせ等に関し、当社の関係会社や他の取引先から回答させて頂くことが適切と判断される場合
                <br />{" "}
                書類や物品を送付する等のために、送付を事業（の一部）とする守秘義務を負った業者に預託する場合
                <br />{" "}
                関係会社と共同事業を営む場合及び合併、会社分割、営業譲渡等により事業の承継が行われる場合
                <br /> 4．ご本人への開示・訂正・削除等
                <br />{" "}
                当社に保有する個人データの開示、訂正、削除、利用もしくは提供の停止等は、ご本人による要請であること及び要請事項が妥当であるかを確認のうえ対応いたします。但し、ご本人からの要請であっても、以下の場合は応じられないことがあります。
                <br /> ご本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                <br />{" "}
                当社事業の適正な実施に重大な支障を来たすおそれがある場合（当社の守秘義務違反等）
                <br /> 他の法令、公益その他に反することとなる場合
                <br /> 5．個人情報保護のための取り組み
                <br /> 当社は、個人情報保護のために、以下の対策をとっております。
                <br />{" "}
                取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置
                <br /> 代表取締役社長を総括責任者とし、管理部マネージャを管理責任者とする体制の構築
                <br /> 個人情報保護の重要性や運用規則に関する役職員教育の実施
                <br /> 内部監査による個人情報の取扱い状況の定期的な確認
                <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                ></MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
