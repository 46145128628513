/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/digital-app-services.jpg";

function ITApplications() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ITアプリケーション管理</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              IT（情報技術）アプリケーション管理サービスは、現代のビジネス環境で不可欠な要素となりました。
              企業はますます多くのデジタルアプリケーションを使用し、これらのアプリケーションを効率的に運用し、最適なパフォーマンスを維持することが求められています。
              <br /> <br />{" "}
              本記事では、ITアプリケーション管理サービスの重要性、利点、ベストプラクティス、そして今後の展望について詳しく説明します。
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ITApplications;
