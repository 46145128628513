/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/erp-model.jpg";

function ITERP() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">ERPとは</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              現代のビジネス環境は、急速なテクノロジーの進化と市場の変化によって、ますます複雑化しています。企業は競争力を維持し、運用を最適化し、効率を向上させ、持続可能な成長を達成するために、新たな方法と戦略を求めています。こうしたニーズに応えるため、ERP（Enterprise
              Resource
              Planning）システムが注目されており、ERPコンサルティングはその導入と活用を支援するための重要な役割を果たしています。本稿では、ERPコンサルティングの重要性について、その利点や役割に焦点を当てながら詳しく探求していきます。
              <br /> <br /> <br />
              ERPは、企業の様々な業務プロセスを一元的に管理し、統合するためのソフトウェアシステムです。これには財務、人事、サプライチェーン管理、製造、販売、顧客関係管理など、さまざまな機能が含まれています。ERPシステムを導入することで、企業は異なる部門間での情報共有や連携がスムーズに行えるようになり、データの一貫性と正確性が確保されます。さらに、リアルタイムの情報表示や分析によって、迅速な意思決定が可能となり、業務効率の向上に寄与します。
              <br /> <br /> <br />{" "}
            </MKTypography>
            <Grid container spacing={1}>
              {/* <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid> */}
              {/* <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ITERP;
