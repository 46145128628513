/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/macbook.png";

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">
              ビジネスの成長に最適なソリューション を 提供します
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              当社は、デジタルソリューションの分野で顧客に革新的なサービスを提供する、リーディングカンパニーです。現代のビジネス環境は、急速なテクノロジーの進化とデジタルトランスフォーメーションの必要性によって大きく変化しています。当社は、この変化に柔軟かつ戦略的に対応し、クライアントの成功を支えるために最適なデジタルソリューションを提供しています。
              <br />{" "}
              当社のミッションは、顧客企業がデジタル化の波に乗り、競争力を高め、市場でのリーダーシップを確立することです。我々は、デジタルテクノロジーの専門知識と経験豊富な専門家チームを駆使し、クライアントのニーズに合わせた最適なソリューションを提供しています。
              当社のサービスポートフォリオは多岐にわたります。デジタル戦略の策定から、ソフトウェア開発、データ分析、AIおよび機械学習の導入、クラウドソリューションの構築まで、幅広い領域でのソリューション提供が可能です。また、ビジネスプロセスの自動化、顧客エンゲージメントの向上、セキュリティ対策の強化など、様々な課題に対応するためのトータルソリューションを提供しています。
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <MKInput type="email" label="メール..." fullWidth />
              </Grid>
              <Grid item xs={4}>
                <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  購読する
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
